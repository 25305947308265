import React from "react"
import { Button, Col, Form, Input, Modal, Row, Grid } from "antd"
import {
  CrossIcon,
  Facebook,
  FacebookBlue,
  Instagram,
  InstagramBlue,
  LinkedIn,
  LinkedInBlue,
  Twitter,
  TwitterBlue,
} from "../../assets/images"
import Toast from "./Toast"
import { useState } from "react"
require("./getInTouchModal.less")
const { useBreakpoint } = Grid

const GetInTouchModal = ({
  getInTouchModalVisible,
  handleHideGetInTouchModalVisible,
}) => {
  const breakpoints = useBreakpoint()
  const [loading, setLoading] = useState(false)
  const [facebookHovered, setFacebookHovered] = useState(false)
  const [linkedInHovered, setLinkedInHovered] = useState(false)
  const [instagramHovered, setInstagramHovered] = useState(false)
  const [twitterHovered, setTwitterHovered] = useState(false)

  const onSubmit = async values => {
    try {
      setLoading(true)
      await fetch(
        `https://production-cpa.ue.r.appspot.com/api/v1/sendgrid/userquery`,
        {
          body: JSON.stringify({
            email: `${values.email}`,
            subject: "Elephant User Query",
            name: `${values.name}`,
            question: `${values.question}`,
          }),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      )
      setLoading(false)
      Toast(
        "Thank you! Your request has been sent to Elephant. Someone will be in touch soon.",
        "success"
      )
      handleHideGetInTouchModalVisible()
    } catch (e) {
      setLoading(false)
      Toast(e.message, "error")
    }
  }

  return (
    <Modal
      width={breakpoints.lg ? "90%" : "100%"}
      visible={getInTouchModalVisible}
      destroyOnClose={true}
      closable={false}
      footer={null}
    >
      <Row justify="center">
        <Col xs={24} md={23}>
          <Row align={breakpoints.md ? "top" : "middle"}>
            <Col
              className="font-weight-bold"
              style={{ fontSize: breakpoints.xxl ? 72 : 35 }}
            >
              Get In Touch
            </Col>
            <Col flex="auto">
              <Row justify="end">
                <Col>
                  <img
                    alt="x to close modal"
                    onClick={handleHideGetInTouchModalVisible}
                    src={CrossIcon}
                    width={breakpoints.md ? 40 : 25}
                    height={breakpoints.xxl ? 40 : breakpoints.md ? 35 : 25}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-30" justify={"center"}>
        <Col xs={24} md={23}>
          <Row gutter={[50, 30]}>
            <Col xl={16} lg={17}>
              <Form onFinish={onSubmit} className="custom-modal-form-items">
                <Row
                  gutter={[
                    0,
                    { lg: 13, md: 13, xl: 13, xxl: 13, sm: 4, xs: 4 },
                  ]}
                >
                  <Col
                    className={`${
                      breakpoints.md ? "font-size-18" : "font-size-14"
                    }`}
                  >
                    Your Name
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your name",
                        },
                      ]}
                    >
                      <Input placeholder="Your Name" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={40}>
                  <Col lg={12} xs={24}>
                    <Row
                      gutter={[
                        0,
                        { lg: 13, md: 13, xl: 13, xxl: 13, sm: 4, xs: 4 },
                      ]}
                    >
                      <Col
                        className={`${
                          breakpoints.md ? "font-size-18" : "font-size-14"
                        }`}
                      >
                        Your Email
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          name="email"
                          rules={[
                            {
                              type: "email",
                              required: true,
                              message:
                                "Please enter a valid email address, e.g. name@domain.com",
                            },
                          ]}
                        >
                          <Input placeholder="e.g. robertfox@example.com" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} lg={12}>
                    <Row
                      gutter={[
                        0,
                        { lg: 13, md: 13, xl: 13, xxl: 13, sm: 4, xs: 4 },
                      ]}
                    >
                      <Col
                        className={`${
                          breakpoints.md ? "font-size-18" : "font-size-14"
                        }`}
                      >
                        Confirm Email
                      </Col>
                      <Col xs={24}>
                        <Form.Item
                          name="confirmEmail"
                          dependencies={["email"]}
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your email address",
                            },
                            ({ getFieldValue }) => ({
                              validator: (_, value) => {
                                if (
                                  !value ||
                                  getFieldValue("email") === value
                                ) {
                                  return Promise.resolve()
                                }
                                return Promise.reject(
                                  new Error("Please confirm your email address")
                                )
                              },
                            }),
                          ]}
                        >
                          <Input placeholder="e.g. robertfox@example.com" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row
                  gutter={[
                    0,
                    { lg: 13, md: 13, xl: 13, xxl: 13, sm: 4, xs: 4 },
                  ]}
                >
                  <Col
                    className={`${
                      breakpoints.md ? "font-size-18" : "font-size-14"
                    }`}
                  >
                    Your Question
                  </Col>
                  <Col xs={24}>
                    <Form.Item
                      name="question"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your question",
                        },
                      ]}
                    >
                      <Input.TextArea
                        placeholder="Type your Question"
                        showCount={true}
                        maxLength={300}
                        autoSize={{ minRows: 6 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                  </Button>
                </Row>
              </Form>
            </Col>
            <Col xl={{ offset: 1, span: 6 }} lg={6}>
              <Row>
                <Col xs={24} className="mt-30">
                  <Row gutter={[0, 10]}>
                    <Col xs={24} className="font-weight-bold">
                      Contact Details
                    </Col>
                    <Col xs={24} className="font-color-4 mt-5">
                      info@elephantcpa.com
                    </Col>
                    <Col xs={24} className="font-color-4 mt-5">
                      1-833-GET-ECPA
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} className="mt-30">
                  <Row gutter={[0, 20]}>
                    <Col xs={24} className="font-weight-bold">
                      Social
                    </Col>
                    <Col>
                      <Row gutter={20}>
                        <Col>
                          <a
                            onMouseEnter={() => {
                              setFacebookHovered(true)
                            }}
                            onMouseLeave={() => setFacebookHovered(false)}
                            href="https://www.facebook.com/ElephantCPA"
                            target="_blank"
                          >
                            {facebookHovered ? (
                              <img
                                alt="facebook hovered icon"
                                src={FacebookBlue}
                                width={40}
                                height={40}
                              />
                            ) : (
                              <img
                                alt="facebook icon"
                                src={Facebook}
                                width={40}
                                height={40}
                              />
                            )}
                          </a>
                        </Col>
                        <Col>
                          <a
                            onMouseEnter={() => {
                              setLinkedInHovered(true)
                            }}
                            onMouseLeave={() => setLinkedInHovered(false)}
                            href="https://www.linkedin.com/company/elephant-cpa/"
                            target="_blank"
                          >
                            {linkedInHovered ? (
                              <img
                                alt="linkedin hovered Icon"
                                src={LinkedInBlue}
                                width={40}
                                height={40}
                              />
                            ) : (
                              <img
                                alt="linkedin Icon"
                                src={LinkedIn}
                                width={40}
                                height={40}
                              />
                            )}
                          </a>
                        </Col>
                        <Col>
                          <a
                            onMouseEnter={() => {
                              setTwitterHovered(true)
                            }}
                            onMouseLeave={() => setTwitterHovered(false)}
                            href="https://twitter.com/ElephantCPA"
                            target="_blank"
                          >
                            {twitterHovered ? (
                              <img
                                alt="twitter hovered Icon"
                                src={TwitterBlue}
                                width={40}
                                height={40}
                              />
                            ) : (
                              <img
                                alt="twitter Icon"
                                src={Twitter}
                                width={40}
                                height={40}
                              />
                            )}
                          </a>
                        </Col>
                        <Col>
                          <a
                            onMouseEnter={() => {
                              setInstagramHovered(true)
                            }}
                            onMouseLeave={() => setInstagramHovered(false)}
                            href="https://www.instagram.com/elephantcpa"
                            target="_blank"
                          >
                            {instagramHovered ? (
                              <img
                                alt="Instagram hovered Icon"
                                src={InstagramBlue}
                                width={40}
                                height={40}
                              />
                            ) : (
                              <img
                                alt="Instagram Icon"
                                src={Instagram}
                                width={40}
                                height={40}
                              />
                            )}
                          </a>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  )
}

export default GetInTouchModal
