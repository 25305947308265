import { Button, Col, Divider, Layout, Row, Grid } from "antd"
import React, { useState } from "react"
import { Link } from "gatsby"
import GetInTouchModal from "../../../shared/Components/GetInTouchModal"
import {
  ElephantLogo,
  Facebook,
  FacebookBlue,
  Instagram,
  InstagramBlue,
  LinkedIn,
  LinkedInBlue,
  MessageIcon,
  PhoneIcon,
  Twitter,
  TwitterBlue,
  Youtube,
  YoutubeBlue,
} from "../../../assets/images"
require("./LandingPageFooter.less")

const { useBreakpoint } = Grid
const { Footer } = Layout
const LandingPageFooter = () => {
  const breakpoints = useBreakpoint()
  const [getInTouchModalVisible, setGetInTouchModalVisible] = useState(false)
  const [facebookHovered, setFacebookHovered] = useState(false)
  const [linkedInHovered, setLinkedInHovered] = useState(false)
  const [instagramHovered, setInstagramHovered] = useState(false)
  const [twitterHovered, setTwitterHovered] = useState(false)
  const [youtubeHovered, setYoutubeHovered] = useState(false)

  const handleShowGetInTouchModalVisible = () => {
    setGetInTouchModalVisible(true)
  }

  const handleHideGetInTouchModalVisible = () => {
    setGetInTouchModalVisible(false)
  }

  return (
    <Footer style={{ marginTop: breakpoints.xl ? -42 : -26 }}>
      <GetInTouchModal
        getInTouchModalVisible={getInTouchModalVisible}
        handleHideGetInTouchModalVisible={handleHideGetInTouchModalVisible}
      />
      <Row justify="center" className="mt-60">
        <Col xs={21}>
          <Row>
            <Col md={5} xs={24}>
              <Row gutter={[0, 20]}>
                <Col xs={24}>
                  <img
                    alt="accounting practice management - Elephant CPA"
                    src={ElephantLogo}
                    width={200}
                    height={50}
                  />
                </Col>
                <Col className="font-color-4" md={24} lg={18} xl={12}>
                  Questions? Get in touch to learn more about Elephant
                </Col>
                <Col xs={24}>
                  <Button
                    onClick={handleShowGetInTouchModalVisible}
                    type="primary"
                    style={{
                      padding: "16px 35px",
                      fontSize: "16px",
                      borderRadius: "15px",
                      height: "auto",
                    }}
                    className="getInTouchButton"
                  >
                    Get In Touch
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={5} xs={24} className="mt-30">
              <Row gutter={[0, 20]}>
                <Col xs={24} className="font-weight-bold">
                  Contact Details
                </Col>
                <Col className="font-color-4" xs={24}>
                  <Row gutter={20}>
                    <Col>
                      <img src={MessageIcon} width={30} height={30} />
                    </Col>
                    <Col className="font-color-4 mt-5">
                      info@elephantcpa.com
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row gutter={20}>
                    <Col>
                      <img
                        alt="Phone Icon"
                        src={PhoneIcon}
                        width={30}
                        height={30}
                      />
                    </Col>
                    <Col className="font-color-4 mt-5">1-833-GET-ECPA</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={5} xs={24} className="mt-30">
              <Row gutter={[0, 20]}>
                <Col xs={24} className="font-weight-bold">
                  Social
                </Col>
                <Col>
                  <Row gutter={10}>
                    <Col>
                      <a
                        onMouseEnter={() => {
                          setFacebookHovered(true)
                        }}
                        onMouseLeave={() => setFacebookHovered(false)}
                        href="https://www.facebook.com/ElephantCPA"
                        target="_blank"
                      >
                        {facebookHovered ? (
                          <img
                            alt="Facebook Hovered Icon"
                            src={FacebookBlue}
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            alt="Facebook Icon"
                            src={Facebook}
                            width={40}
                            height={40}
                          />
                        )}
                      </a>
                    </Col>
                    <Col>
                      <a
                        onMouseEnter={() => {
                          setLinkedInHovered(true)
                        }}
                        onMouseLeave={() => setLinkedInHovered(false)}
                        href="https://www.linkedin.com/company/elephant-cpa/"
                        target="_blank"
                      >
                        {linkedInHovered ? (
                          <img
                            alt="linkedIn Hovered Icon"
                            src={LinkedInBlue}
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            alt="Linkedin Icon"
                            src={LinkedIn}
                            width={40}
                            height={40}
                          />
                        )}
                      </a>
                    </Col>
                    <Col>
                      <a
                        onMouseEnter={() => {
                          setTwitterHovered(true)
                        }}
                        onMouseLeave={() => setTwitterHovered(false)}
                        href="https://twitter.com/ElephantCPA"
                        target="_blank"
                      >
                        {twitterHovered ? (
                          <img
                            alt="Twitter Hovered Icon"
                            src={TwitterBlue}
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            alt="Twitter Icon"
                            src={Twitter}
                            width={40}
                            height={40}
                          />
                        )}
                      </a>
                    </Col>
                    <Col>
                      <a
                        onMouseEnter={() => {
                          setInstagramHovered(true)
                        }}
                        onMouseLeave={() => setInstagramHovered(false)}
                        href="https://www.instagram.com/elephantcpa"
                        target="_blank"
                      >
                        {instagramHovered ? (
                          <img
                            alt="Instagram hovered Icon"
                            src={InstagramBlue}
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            alt="Instagram Icon"
                            src={Instagram}
                            width={40}
                            height={40}
                          />
                        )}
                      </a>
                    </Col>
                    <Col
                      style={
                        !youtubeHovered
                          ? {
                              border: "0.5px solid #a4a4a66b",
                              borderRadius: "10px",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              marginLeft: "5px",
                            }
                          : {
                              border: "0.5px solid #a4a4a66b",
                              borderRadius: "10px",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              backgroundColor: "#1236FF",
                              marginLeft: "5px",
                            }
                      }
                    >
                      <a
                        onMouseEnter={() => {
                          setYoutubeHovered(true)
                        }}
                        onMouseLeave={() => setYoutubeHovered(false)}
                        href="https://www.youtube.com/@elephantcpa"
                        target="_blank"
                      >
                        {youtubeHovered ? (
                          <img
                            alt="Instagram hovered Icon"
                            src={YoutubeBlue}
                            width={30}
                            height={40}
                          />
                        ) : (
                          <img
                            alt="Instagram Icon"
                            src={Youtube}
                            width={30}
                            height={40}
                          />
                        )}
                      </a>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={4} xs={24} className="mt-30">
              <Row gutter={[0, 20]}>
                <Col xs={24} className="font-weight-bold">
                  Company
                </Col>
                <Col className="font-color-4" xs={24}>
                  <Row gutter={20}>
                    <Link to="/termsOfUse">
                      <Col className="font-color-4 mt-5">Terms of Use</Col>
                    </Link>
                  </Row>
                </Col>
                <Col className="font-color-4" xs={24}>
                  <Row gutter={20}>
                    <Link to="/privacyPolicy">
                      <Col className="font-color-4 mt-5">Privacy Policy</Col>
                    </Link>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col md={4} xs={24} className="mt-30">
              <Row gutter={[0, 20]}>
                <Col xs={24} className="font-weight-bold">
                  Reviews
                </Col>
                <Col className="font-color-4" xs={24}>
                  <a
                    target="_blank"
                    href="https://www.capterra.com/reviews/249874/Elephant?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                  >
                    {" "}
                    <img
                      className="reviewImage"
                      border="0"
                      src="https://assets.capterra.com/badge/96d5467f22897fb4715e7b78b2f2dda3.svg?v=2206454&p=249874"
                    />{" "}
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
        </Col>
      </Row>
      <Row justify="center" style={{ marginBottom: 25 }}>
        <Col className="font-color-4">
          © {new Date().getFullYear()} ElephantCPA. All rights reserved.
        </Col>
      </Row>
    </Footer>
  )
}

export default LandingPageFooter
