import { notification } from "antd"

const Toast = (message, type = "info") => {
  // @ts-ignore
  notification[type]({
    message: message,
    description: "",
    placement: "topLeft",
    duration: 5,
    style: { borderRadius: "30px" },
  })
}

export default Toast
