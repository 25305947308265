import React from "react"
import { Button, Col, Layout, Row, Grid } from "antd"
import GetInTouchModal from "../../../shared/Components/GetInTouchModal"
import { useState } from "react"
import { ElephantLogo, MenuIcon } from "../../../assets/images"
import { Link } from "gatsby"

require("./header.less")

const { useBreakpoint } = Grid
const { Header } = Layout

const LandingPageHeader = ({ setSidebarCollapsed, setClickedFreeTrial }) => {
  const [getInTouchModalVisible, setGetInTouchModalVisible] = useState(false)
  /*   const [sidebarCollapsed, setSidebarCollapsed] = useState(true) */
  const breakpoints = useBreakpoint()
  const handleShowGetInTouchModalVisible = () => {
    setGetInTouchModalVisible(true)
  }

  const handleHideGetInTouchModalVisible = () => {
    setGetInTouchModalVisible(false)
  }

  return (
    <Header className="landing-page-header header-button">
      <GetInTouchModal
        getInTouchModalVisible={getInTouchModalVisible}
        handleHideGetInTouchModalVisible={handleHideGetInTouchModalVisible}
      />
      <Row justify="center">
        <Col xs={21}>
          <Row
            align="middle"
            justify={!breakpoints.md ? "space-between" : "start"}
          >
            {!breakpoints.md && (
              <Col>
                <img
                  onClick={() => {
                    setSidebarCollapsed(false)
                  }}
                  src={MenuIcon}
                  alt="drop down menu"
                  height={25}
                  width={25}
                />
              </Col>
            )}
            <Link to="/">
              <Col
                className="mt-20"
                style={{ display: "flex", height: "77px" }}
              >
                <img
                  alt="Elephant accounting practice management"
                  src={ElephantLogo}
                  height={50}
                  width={breakpoints.md ? 200 : 150}
                />
              </Col>
            </Link>

            {breakpoints.md && (
              <Col flex={"auto"}>
                <Row justify="end" gutter={10}>
                  <Col>
                    <Button
                      type="primary "
                      href="https://app.elephantcpa.com/signup"
                      block
                    >
                      Get Free Trial
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={handleShowGetInTouchModalVisible}
                      type="default"
                      block
                    >
                      Get In Touch
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      href="https://app.elephantcpa.com"
                      type="primary "
                      block
                    >
                      Login
                    </Button>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Header>
  )
}

export default LandingPageHeader
